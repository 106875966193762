import React, { useState, useRef } from "react";
import { TextField, IconButton, Button, Box } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete } from "@react-google-maps/api";
import "./SearchSection.css";

const SearchSection = ({ onSearch }) => {
  const [location, setLocation] = useState("New York");
  const [checkIn, setCheckIn] = useState(
    new Date(new Date().setDate(new Date().getDate() + 2))
      .toISOString()
      .split("T")[0]
  );
  const [checkOut, setCheckOut] = useState(
    new Date(new Date().setDate(new Date().getDate() + 3))
      .toISOString()
      .split("T")[0]
  );
  const [guests, setGuests] = useState(2);
  const [rooms, setRooms] = useState(1);
  const [coordinates, setCoordinates] = useState(null);

  const [isLocationInputVisible, setIsLocationInputVisible] = useState(false);
  const [isCheckInInputVisible, setIsCheckInInputVisible] = useState(false);
  const [isCheckOutInputVisible, setIsCheckOutInputVisible] = useState(false);
  const [isGuestsInputVisible, setIsGuestsInputVisible] = useState(false);

  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    setLocation(place.formatted_address);
    setCoordinates({
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
    setIsLocationInputVisible(false); // Hide the input after selection
  };

  const handleSearch = () => {
    onSearch({ location, checkIn, checkOut, guests, rooms, coordinates });
  };

  const toggleLocationInput = () => {
    setIsLocationInputVisible(!isLocationInputVisible);
  };

  const toggleCheckInInput = () => {
    setIsCheckInInputVisible(!isCheckInInputVisible);
  };

  const toggleCheckOutInput = () => {
    setIsCheckOutInputVisible(!isCheckOutInputVisible);
  };

  const toggleGuestsInput = () => {
    setIsGuestsInputVisible(!isGuestsInputVisible);
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getWeekDay = (date) => {
    const options = { weekday: "long" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <Box
      sx={{ width: "100%", position: "relative", textAlign: "center" }}
      className="search-section-container"
    >
      <div className="search-fields filter-container">
        <div className="search-field" onClick={toggleLocationInput}>
          {isLocationInputVisible ? (
            <Autocomplete
              onLoad={(autocomplete) =>
                (autocompleteRef.current = autocomplete)
              }
              onPlaceChanged={handlePlaceChanged}
            >
              <TextField
                fullWidth
                variant="outlined"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                  setCoordinates(null); // Reset coordinates if the user types manually
                }}
                autoFocus
                placeholder="Enter a location"
              />
            </Autocomplete>
          ) : (
            <>
              <div className="search-field-label">
                City, Property, or Location
              </div>
              <div className="search-field-value">{location}</div>
            </>
          )}
        </div>
        <div className="search-field" onClick={toggleCheckInInput}>
          {isCheckInInputVisible ? (
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={checkIn}
              onChange={(e) => setCheckIn(e.target.value)}
              onBlur={toggleCheckInInput}
              autoFocus
            />
          ) : (
            <>
              <div className="search-field-label">Check-In</div>
              <div className="search-field-value">{formatDate(checkIn)}</div>
              <div className="search-field-label">{getWeekDay(checkIn)}</div>
            </>
          )}
        </div>
        <div className="search-field" onClick={toggleCheckOutInput}>
          {isCheckOutInputVisible ? (
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
              onBlur={toggleCheckOutInput}
              autoFocus
            />
          ) : (
            <>
              <div className="search-field-label">Check-Out</div>
              <div className="search-field-value">{formatDate(checkOut)}</div>
              <div className="search-field-label">{getWeekDay(checkOut)}</div>
            </>
          )}
        </div>
        <div className="search-field" onClick={toggleGuestsInput}>
          {isGuestsInputVisible ? (
            <div>
              <div>
                <div className="search-field-label">Rooms</div>
                <IconButton onClick={() => setRooms(rooms + 1)}>
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton onClick={() => setRooms(rooms - 1)}>
                  <KeyboardArrowDown />
                </IconButton>
              </div>
              <div>
                <div className="search-field-label">Guests</div>
                <IconButton onClick={() => setGuests(guests + 1)}>
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton onClick={() => setGuests(guests - 1)}>
                  <KeyboardArrowDown />
                </IconButton>
              </div>
            </div>
          ) : (
            <>
              <div className="search-field-label">Rooms & Guests</div>
              <div className="search-field-value">
                {rooms} Room {guests} Guests
              </div>
            </>
          )}
        </div>
      </div>
      <div className="search-button-container">
        <Button
          className="search-button"
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
    </Box>
  );
};

export default SearchSection;
