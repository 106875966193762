import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HotelContent from "./components/HotelContent";
import CheckOut from "./components/RoomCheckOut/CheckOut";
import ScrollToTop from "./components/ScrollToTop";
import AdminDashBoard from "./components/AdminDashBoard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ActivateSignUp from "./components/ActivateSignup";
import ActiveClients from "./components/ActiveClients";

const theme = createTheme({
  typography: {
    fontFamily: "Microsoft JhengHei UI",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <div>
          <Routes>
            <Route exact path="/" element={<Header />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/AdminDashBoard" element={<AdminDashBoard />} />
            <Route path="/activate-signup" element={<ActivateSignUp />} />
            <Route path="/active-clients" element={<ActiveClients />} />
            <Route path="/discount-coupons" element={<AdminDashBoard />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
