import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import btcImage from "../assets/btc-image.png";
import creditCardImage from "../assets/credit-card.png";
import payNowImage from "../assets/pay-now-image.png";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: "Microsoft JhengHei UI",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "30.48px",
}));

const StyledPrice = styled(Typography)(({ theme }) => ({
  fontFamily: "Microsoft JhengHei UI",
  fontWeight: 400,
  fontSize: "36px",
  lineHeight: "48px",
}));

const ProceedButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007bff",
  color: "white",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const PaymentModal = ({ open, onClose, amount, handlePayment }) => {
  const handleProceedClick = () => {
    handlePayment();
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <StyledDialogTitle>Proceed Payment with</StyledDialogTitle>
              <StyledPrice variant="h4">US ${amount}</StyledPrice>
              <RadioGroup defaultValue="payNow">
                <FormControlLabel
                  value="btc"
                  control={<Radio />}
                  label="BTC (Bill to Company)"
                />
                <FormControlLabel
                  value="payNow"
                  control={<Radio />}
                  label="Pay Now"
                />
              </RadioGroup>
              <Typography variant="body2" color="textSecondary">
                By proceeding, I agree to Bizflyte User Agreement, Terms of
                Service and Cancellation & Property Booking Policies.
              </Typography>
              <Box mt={2} mb={2}>
                <ProceedButton
                  fullWidth
                  variant="contained"
                  onClick={handleProceedClick}
                >
                  Proceed
                </ProceedButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={creditCardImage}
                  alt="Credit Card"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  flexBasis: "20%",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  <img
                    src={btcImage}
                    alt="BTC"
                    style={{
                      width: "24px",
                      height: "24px",
                      verticalAlign: "middle",
                      marginRight: "8px",
                    }}
                  />
                  BTC - Bill to Company <br />
                  Your company will pay for this trip
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  flexBasis: "20%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: "16px" }}
                >
                  <img
                    src={payNowImage}
                    alt="Pay Now"
                    style={{
                      width: "24px",
                      height: "24px",
                      verticalAlign: "middle",
                      marginRight: "8px",
                    }}
                  />
                  Pay Now - Available Options <br />
                  Use Credit Cards, Debit Cards, Netbanking and Wallets to pay
                  for this trip
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
