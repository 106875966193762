import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  IconButton,
  Button,
  Menu,
  MenuItem,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Avatar,
  Modal,
  Grid,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AnalyticsIcon from "@mui/icons-material/BarChart";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import profileImage from "../assets/profileImage.png";
import corporateUser from "../assets/corporateUser.jpg";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Microsoft JhengHei UI",
  fontWeight: 700,
  fontSize: "24px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const Sidebar = () => {
  const navigate = useNavigate();

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/AdminDashBoard" },
    {
      text: "Activate Sign up",
      icon: <GroupAddIcon />,
      path: "/activate-signup",
    },
    { text: "Active Clients", icon: <PeopleIcon />, path: "/active-clients" },
    {
      text: "Discount Coupons",
      icon: <LocalOfferIcon />,
      path: "/discount-coupons",
    },
  ];

  return (
    <Box
      sx={{
        width: 240,
        height: "100vh",
        backgroundColor: "#FFFFFF",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        paddingTop: 2,
      }}
    >
      <Box
        sx={{
          paddingLeft: 2,
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <AnalyticsIcon sx={{ color: "#000", marginRight: 1 }} />
        <Box>
          <Typography
            variant="h6"
            noWrap
            sx={{
              fontFamily: "Microsoft JhengHei UI",
              fontWeight: 700,
              color: "#000000",
            }}
          >
            Control Panel
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            sx={{
              fontFamily: "Microsoft JhengHei UI",
              fontWeight: 400,
              color: "#555555",
            }}
          >
            Analytics
          </Typography>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
        {menuItems.map((item) => (
          <Box
            key={item.text}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                item.path === window.location.pathname
                  ? "#0054A1"
                  : "transparent",
              "&:hover": {
                backgroundColor: "#0054A1",
                "& .MuiTypography-root": {
                  color: "#FFFFFF",
                },
                "& .MuiSvgIcon-root": {
                  color: "#FFFFFF",
                },
              },
            }}
            onClick={() => navigate(item.path)}
          >
            <Box
              sx={{
                color:
                  item.path === window.location.pathname ? "#FFFFFF" : "#000",
              }}
            >
              {item.icon}
            </Box>
            <Typography
              variant="body1"
              sx={{
                marginLeft: "10px",
                color:
                  item.path === window.location.pathname ? "#FFFFFF" : "#000",
                fontFamily: "Microsoft JhengHei UI",
                fontWeight: 500,
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const ActiveClients = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const openMenu = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        // `http://localhost:8080/api/admin/panel/users/activated?page=${page}&size=${rowsPerPage}`,
        `http://104.237.9.131:8080/api/admin/panel/users/activated?page=${page}&size=${rowsPerPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUsers(data.content);
        setTotalElements(data.totalElements);
      } else {
        console.error("Failed to fetch users.");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  const handleAction = async (action) => {
    const activeOrReject = action === "Activate";
    try {
      const response = await fetch(
        // `http://localhost:8080/api/admin/panel/users/action?userId=${selectedUserId}&activeOrReject=${activeOrReject}`,
        `http://104.237.9.131:8080/api/admin/panel/users/action?userId=${selectedUserId}&activeOrReject=${activeOrReject}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (response.ok) {
        closeMenu();
        fetchUsers();
      } else {
        console.error("Failed to perform action.");
      }
    } catch (error) {
      console.error("Error performing action:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((user) => user.userId);
      setSelectedUsers(newSelecteds);
      return;
    }
    setSelectedUsers([]);
  };

  const handleCheckboxClick = (event, userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelected);
  };

  const isSelected = (userId) => selectedUsers.indexOf(userId) !== -1;

  const handleProfileClick = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    navigate("/");
  };
  const handleModifyPayment = () => {
    setOpenDialog(true);
    closeMenu();
  };

  const handleModifyServiceFee = () => {
    setOpenDialog(true);
    closeMenu();
  };

  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={1} p={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <StyledTypography variant="h4">
            <AssignmentTurnedInIcon fontSize="large" />
            Active Clients
          </StyledTypography>
          <Box display="flex" alignItems="center">
            <TextField variant="outlined" placeholder="Search" size="small" />
            <IconButton>
              <NotificationsIcon />
            </IconButton>
            <IconButton onClick={handleProfileClick}>
              <Avatar src={profileImage} alt="Profile" />
            </IconButton>
            <Menu
              anchorEl={profileMenuAnchorEl}
              open={Boolean(profileMenuAnchorEl)}
              onClose={() => setProfileMenuAnchorEl(null)}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
        <FilterBox>
          <Button
            variant="outlined"
            onClick={handleSelectAllClick}
            sx={{ textTransform: "none" }}
          >
            All
          </Button>
          <FormControl variant="outlined" style={{ minWidth: 120 }}>
            <InputLabel>City</InputLabel>
            <Select label="City">
              {/* City Filter Options to be implemented later */}
            </Select>
          </FormControl>
          <TextField label="Search" variant="outlined" />
          <StyledButton variant="contained" color="primary">
            Approve
          </StyledButton>
        </FilterBox>
        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedUsers.length > 0 &&
                      selectedUsers.length < users.length
                    }
                    checked={
                      users.length > 0 && selectedUsers.length === users.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Corporate Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Person Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Signup Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const isItemSelected = isSelected(user.userId);
                return (
                  <TableRow
                    key={user.userId}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) =>
                          handleCheckboxClick(event, user.userId)
                        }
                      />
                    </TableCell>
                    <TableCell>{user.corporateName || "-"}</TableCell>
                    <TableCell>{user.personName || "-"}</TableCell>
                    <TableCell>{user.emailId}</TableCell>
                    <TableCell>{user.phoneNumber || "-"}</TableCell>
                    <TableCell>{user.city || "-"}</TableCell>
                    <TableCell>
                      {new Date(user.createdTS).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(event) => openMenu(event, user.userId)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) && selectedUserId === user.userId
                        }
                        onClose={closeMenu}
                      >
                        <MenuItem onClick={() => handleAction("Activate")}>
                          Active
                        </MenuItem>
                        <MenuItem onClick={() => handleAction("Reject")}>
                          Inactive
                        </MenuItem>
                        <MenuItem onClick={() => handleModifyPayment()}>
                          Modify Payment
                        </MenuItem>
                        <MenuItem onClick={() => handleModifyServiceFee()}>
                          Modify Service Fee
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" m={2}>
            <Pagination
              count={Math.ceil(totalElements / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem {...item} style={{ margin: "0 4px" }} />
              )}
            />
          </Box>
        </StyledTableContainer>

        <Modal open={openDialog} onClose={handleCloseDialog}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "95vh",
              maxHeight: "95vh",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Avatar
                  src={corporateUser}
                  alt="User"
                  sx={{ width: "100%", height: "auto", borderRadius: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Corporate Name:</strong>{" "}
                      {selectedUser?.corporateName || "Elize"}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Person Name:</strong>{" "}
                      {selectedUser?.personName || "Mr. John"}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong>{" "}
                      {selectedUser?.emailId || "john@gmail.com"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Phone:</strong>{" "}
                      {selectedUser?.phoneNumber || "+1 1234567892"}
                    </Typography>
                    <Typography variant="body1">
                      <strong>City:</strong> {selectedUser?.city || "Toronto"}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Sign up Date:</strong>{" "}
                      {selectedUser?.createdTS
                        ? new Date(selectedUser.createdTS).toLocaleDateString()
                        : "21.03.24"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Tabbed Section */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="User Details Tabs"
              sx={{ mt: 2 }}
            >
              <Tab label="Flight" />
              <Tab label="Hotels" />
              <Tab label="Cab" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
              {/* Flight Tab Content */}
              {tabIndex === 0 && (
                <Box component="form">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="New Booking"
                        fullWidth
                        size="small"
                        defaultValue="10%"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="or INR"
                        fullWidth
                        size="small"
                        defaultValue="100"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Modification"
                        fullWidth
                        size="small"
                        defaultValue="10%"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="or INR"
                        fullWidth
                        size="small"
                        defaultValue="50"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Cancellation"
                        fullWidth
                        size="small"
                        defaultValue="10%"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="or INR"
                        fullWidth
                        size="small"
                        defaultValue="100"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" fullWidth>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Payment Methods
                  </Typography>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Eligible for BTC"
                  />
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                      <Select fullWidth size="small" defaultValue={2}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="BTC Limit"
                        fullWidth
                        size="small"
                        defaultValue="INR 2,00,000"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" fullWidth>
                        Confirm
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {tabIndex === 1 && (
                <Box>
                  <Typography>
                    Hotel booking details and settings go here...
                  </Typography>
                </Box>
              )}

              {tabIndex === 2 && (
                <Box>
                  <Typography>
                    Cab booking details and settings go here...
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default ActiveClients;
