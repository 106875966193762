import React from "react";
import { Box, Typography, Container, Link, Button } from "@mui/material";
import RoomDetails from "./RoomDetails";
import "./CheckOut.css";
import { useLocation } from "react-router-dom";
import logo from "../../assets/logo/logo512.png";

const CheckOut = () => {
  const location = useLocation();
  const { hotel, room, rate, searchParams } = location.state || {};

  return (
    <Box
      sx={{
        backgroundColor: "#0054A1",
        height: "100px",
        width: "100%",
        margin: "0 auto",
        position: "relative",
      }}
    >
      <Container
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "30px 0",
          }}
        >
          <Typography variant="h6" color="inherit">
            <img src={logo} alt="Logo" width="100px" />
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              fontSize: "14px",
              fontFamily: "Noto Sans, sans-serif",
              fontWeight: "300",
            }}
          >
            <Link href="/offers" underline="none" sx={{ color: "#FFFFFF" }}>
              Offers
            </Link>
            <Link
              href="/check-status"
              underline="none"
              sx={{ color: "#FFFFFF" }}
            >
              Check Status
            </Link>
            <Link
              href="/add-members"
              underline="none"
              sx={{ color: "#FFFFFF" }}
            >
              Add Members
            </Link>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "4px 20px !important",
                fontSize: "14px",
                fontFamily: "Noto Sans, sans-serif",
                fontWeight: "300",
                textTransform: "capitalize",
                width: "85px",
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
      <div className="checkout-page">
        <RoomDetails
          hotel={hotel}
          room={room}
          rate={rate}
          searchParams={searchParams}
        />
      </div>
    </Box>


  );
};

export default CheckOut;
