import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./RoomDetails.css";
import { Grid, Typography, Card, CardContent, Container } from "@mui/material";
import image from "./template-hotel.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import PaymentModal from "../PaymentModal";

const RoomDetails = ({ hotel, room, rate, searchParams }) => {
  const underlineStyle = { textDecoration: "underline" };
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [amount, setAmount] = useState(0);
  const nights =
    searchParams && searchParams.checkIn && searchParams.checkOut
      ? Math.ceil(
          (new Date(searchParams.checkOut) - new Date(searchParams.checkIn)) /
            (1000 * 60 * 60 * 24)
        )
      : 0;

  useEffect(() => {
    if (rate && searchParams) {
      const fullAmount =
        parseFloat(rate.net * nights) +
        (rate.taxes ? parseFloat(rate.taxes.taxes[0].amount) : 0.0);
      setAmount(fullAmount);
    }
  }, [rate, searchParams, nights]);

  const handleClick = (newRating) => {
    setRating(newRating);
  };

  const handleMouseEnter = (newHover) => {
    setHover(newHover);
  };

  const handleMouseLeave = () => {
    setHover(rating);
  };

  const currencySymbols = {
    USD: "$",
    EUR: "€",
    JPY: "¥",
    GBP: "£",
    AUD: "AU$",
    CAD: "CA$",
  };

  const getCurrencySymbol = ({ hotel }) => {
    const symbol = currencySymbols[hotel.currency];
    console.log(symbol);
    return symbol;
  };

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  if (!hotel || !room || !rate || !searchParams) {
    return <div>Return to Home page</div>;
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const injectCSS = () => {
    const link = document.createElement("link");
    link.href = "./razorpay-custom.css";
    link.type = "text/css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  };

  const createOrder = async () => {
    const response = await fetch(
      // `http://localhost:8080/api/accommodation/api/payment/createOrder?amount=1&currency=INR`,
      `http://104.237.9.131:8080/api/accommodation/api/payment/createOrder?amount=1&currency=INR`,
      // `http://localhost:8080/api/payment/createOrder?amount=${Math.round(
      //   amount
      // )}&currency=INR`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return await response.json();
  };

  const verifyPayment = async (orderId, paymentId, signature) => {
    console.log("orderId=", orderId);
    console.log("paymentId=", paymentId);
    console.log("signature=", signature);
    const response = await fetch(
      // `http://localhost:8080/api/accommodation/api/payment/verifyPayment?orderId=${orderId}&paymentId=${paymentId}&signature=${signature}`,
      `http://104.237.9.131:8080/api/accommodation/api/payment/verifyPayment?orderId=${orderId}&paymentId=${paymentId}&signature=${signature}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return await response.text();
  };
  const handlePayment = async () => {
    console.log("amount is =", amount);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const order = await createOrder();

    const options = {
      key: "rzp_test_dRH92j43X0Pcxi",
      amount: Math.round(amount * 100), // Amount in paise
      currency: hotel.currency,
      name: "Bizflyte",
      description: "Test Hotel Checkout Transaction",
      order_id: order.id,
      handler: async function (response) {
        const result = await verifyPayment(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          response.razorpay_signature
        );
        alert(result);
        if (result === "Payment is valid") {
          // call the /accommodation/booking/book-hotel api after constructing the Proper BookingHotelRequestDTO
          // from existing data such as rate

          // Hardcoded booking request DTO with dynamic rateKey
          const bookingRequest = {
            holder: {
              name: "HolderFirstName",
              surname: "HolderLastName",
            },
            rooms: [
              {
                rateKey: rate.rateKey, // Dynamic rateKey from the rate object
                paxes: [
                  {
                    roomId: 1,
                    type: "AD", // Adult type
                    name: "First Adult Name",
                    surname: "Surname",
                  },
                ],
              },
            ],
            clientReference: "IntegrationAgency",
            remark: "Booking remarks are to be written here.",
            tolerance: 2,
          };
          const bookingResponse = await fetch(
            // "http://localhost:8080/api/accommodation/booking/book-hotel",
            "http://104.237.9.131:8080/api/accommodation/booking/book-hotel",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
              body: JSON.stringify(bookingRequest),
            }
          );

          if (bookingResponse.status === 200) {
            const responseData = await bookingResponse.json();
            const bookingReference = responseData.booking.reference;
            alert(
              "Payment is success, Booking is confirmed with booking id: " +
                bookingReference
            );
            navigate("/");
          } else {
            alert("Booking failed, please try again");
          }
        }
      },
      prefill: {
        name: "Aditya Ducima",
        email: "your.email@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#0054a1",
      },
      modal: {
        ondismiss: function () {
          console.log("Checkout form closed");
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // Resizing the modal after it has opened
    setTimeout(() => {
      const modal = document.querySelector(".razorpay-checkout-frame");
      if (modal) {
        if (modal) {
          modal.style.height = "90%";
          modal.style.width = "350px";
          modal.style.top = "50%";
          modal.style.left = "50%";
          modal.style.transform = "translate(-50%, -50%)";
        }
      }
    }, 1000); // Adjust the timeout if necessary
  };

  const symbol = getCurrencySymbol({ hotel });
  return (
    <Container>
      <div className="row mt-3">
        <div className="col-lg-8">
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography fontSize={20}>Review your Booking</Typography>
                  <Typography fontSize={24} fontWeight={600} marginTop={0.5}>
                    {hotel.name}
                  </Typography>
                  <Typography fontSize={16} lineHeight={1.2}>
                    {hotel.zoneName}
                  </Typography>
                  <Typography fontSize={14} lineHeight={1.2}>
                    {hotel.destinationName}
                  </Typography>
                  <Typography>
                    <div class="star-rating mb-2">
                      {[...Array(5)].map((star, index) => {
                        const starClass =
                          index < rating
                            ? "on"
                            : index < hover
                            ? "hover"
                            : "off";

                        return (
                          <span
                            key={index}
                            className={`star ${starClass}`}
                            onClick={() => handleClick(index + 1)}
                            onMouseEnter={() => handleMouseEnter(index + 1)}
                            onMouseLeave={handleMouseLeave}
                          >
                            ★
                          </span>
                        );
                      })}
                      <span className="rating-content">
                        {rating > 0 ? rating : null}
                      </span>
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={image}
                    alt="hotel"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid spacing={1}>
                <div className="row border-check-cont">
                  <div className="col-md-3 column check-border">
                    <label htmlFor="checkIn" className="check-label">
                      Check-In
                    </label>
                    <br />
                    {searchParams.checkIn}
                  </div>
                  <div className="col-md-3 column check-border">
                    <label htmlFor="checkOut" className="check-label">
                      Check-Out
                    </label>
                    <br />
                    {searchParams.checkOut}
                  </div>
                  <div className="col-md-3 column check-border">
                    <label htmlFor="rooms" className="check-label">
                      Rooms & Guests
                    </label>
                    <br />
                    {searchParams.rooms} <span>Room</span> {searchParams.guests}{" "}
                    <span>Guests</span>
                  </div>
                  <div className="col-md-3 column check-border check-border-last">
                    <label htmlFor="night" className="check-label">
                      Nights
                    </label>
                    <br />
                    {nights}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="room-type-text">{room.name}</div>
                    <p className="room-categories">
                      <small>
                        {rate.adults} adults & {rate.children} children Adult
                      </small>
                    </p>
                    <ul className="room-list">
                      <li>
                        <small>{rate.boardName}</small>
                      </li>
                      <li>
                        <small>
                          <span style={underlineStyle}>Non-Refundable</span> |
                          Refund is not applicable for booking{" "}
                          <span style={underlineStyle}>
                            Cancellation policy details
                          </span>
                        </small>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <div className="text-end">
                      <a href="">See Conclusion</a>
                    </div>
                  </div>
                </div>
              </Grid>
              <div className="row mb-3 mt-3">
                <div className="important-info colored-text">
                  <div className="col-lg-10 d-flex flex-column">
                    {/* <Grid
                      spacing={1}
                    > */}
                    <div className="room-type-text color-orange">
                      Important Information
                    </div>
                    <ul className="room-list">
                      <li>
                        <small>
                          Passport, Aadhar, Driving License and Govt. ID are
                          accepted as ID proof(s)
                        </small>
                      </li>
                      <li>
                        <small>Pets are not allowed</small>
                      </li>
                      <li>
                        <small>
                          Smoking within the premises is not allowed
                        </small>
                      </li>
                    </ul>
                    {/* </Grid> */}
                  </div>
                  <div className="col-lg-2 d-flex flex-column text-end">
                    <a href="">View all</a>
                  </div>
                </div>
              </div>
              <Grid>
                <div className="room-type-text">Cancellation Policy</div>
                <ul className="cancel-policy">
                  <li className="red-text">
                    <small>Cancel up to Tue 2 Jul 2024</small>
                  </li>
                  <li className="red-text">
                    <small>
                      The full cost of the booking will be refunded to you. No
                      cancellation change applied
                    </small>
                  </li>
                  <li className="red-text">
                    <small>Cancel on or after Wed 3 Jul 2024</small>
                  </li>
                  <li className="red-text">
                    <small>
                      A cancellation charge of {symbol}
                      {rate.cancellationPolicies.amount} will apply
                    </small>
                  </li>
                </ul>
              </Grid>
              <Grid>
                <div className="check-policy mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="myCheckbox"
                    name="myCheckbox"
                    onChange={handleChange}
                  />

                  <label className="form-check-label">
                    I accept the cancellation policy for this booking and the{" "}
                    <a href="">Terms & Conditions.</a>
                  </label>
                </div>
              </Grid>
            </CardContent>
          </Card>
          <div className="mt-3">
            <Card>
              <CardContent>
                <h5 class="card-title mb-3">Fill your details</h5>
                <form>
                  <div class="row">
                    <div class="col-md-2 mb-3">
                      <label for="title" class="form-label">
                        Title
                      </label>
                      <select class="form-select" id="title">
                        <option selected>Mr</option>
                        <option>Mrs</option>
                        <option>Ms</option>
                      </select>
                    </div>
                    <div class="col-md-5 mb-3">
                      <label for="firstName" class="form-label">
                        First Name
                      </label>
                      <input
                        class="form-control"
                        id="firstName"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter your first name"
                      />
                    </div>
                    <div class="col-md-5 mb-3">
                      <label for="lastName" class="form-label">
                        Last Name
                      </label>
                      <input
                        class="form-control"
                        id="lastName"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Enter your Last"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="passportId" class="form-label">
                        Passport / ID
                      </label>
                      <input
                        class="form-control"
                        id="passport"
                        type="text"
                        value={formData.passport}
                        onChange={handleChange}
                        placeholder="Id"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="email" class="form-label">
                        Email ID
                      </label>
                      <input
                        class="form-control"
                        id="email"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email ID"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="mobileNumber" class="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        placeholder="+91 Contact Number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-md-6 mb-3">
                      <input
                        type="checkbox"
                        id="myCheckbox"
                        name="myCheckbox"
                        onChange={handleChange}
                      />
                      <label
                        class="form-check-label gst-details"
                        for="enterGstDetails"
                      >
                        Enter GST Details (Optional)
                      </label>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                  >
                    + Add Guest
                  </button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="mt-0">
            <Card>
              <CardContent>
                <div class="d-flex flex-column">
                  <div class="mb-3">
                    <div className="price-breakup">Price Breakup</div>
                    <div className="price-content">
                      1 Room x {nights} Nights{" "}
                      <span className="currency">
                        {symbol}
                        {rate.net * nights}
                      </span>
                    </div>
                    <div className="price-type">Base price</div>
                    <div className="price-content">
                      Total Discount:
                      <span className="currency">{symbol}0.00</span>
                    </div>
                    <div className="price-content">
                      Price after Discounts:
                      <span className="currency">
                        {symbol}
                        {rate.net * nights}
                      </span>
                    </div>
                    <div className="price-content">
                      Hotel Taxes:
                      <span className="currency">
                        {symbol}
                        {rate.taxes ? rate.taxes.taxes[0].amount : "0.00"}
                      </span>
                    </div>
                    <div class="donate-checkbox form-check mb-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="donate"
                      />
                      <label class="form-check-label" for="donate">
                        Donate $5 to build resilient travel destination{" "}
                        <a href="#">T&amp;C Apply</a>
                      </label>
                    </div>
                    <div className="total-price">
                      <strong>Total Amount to be paid:</strong>
                      <span>
                        <strong>
                          {symbol}
                          {amount}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="mt-3">
            <Card>
              <CardContent>
                <div className="">
                  <div class="coupon-codes mb-3 cashback">
                    <div className="price-breakup">Coupon Codes</div>
                    <div className="form-check mt-2">
                      <input type="radio" className="form-check-input" />
                      <label className="form-check-label color-cashback">
                        CASHBACK
                      </label>
                    </div>
                    <div className="price-cashback">
                      Congratulations!! Cashback of $50 will be credited to your
                      card within 3 days of booking
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div>
            {/* <Card>
            <CardContent> */}
            <button class="checkout-btn mb-2" onClick={handleOpenModal}>
              Checkout
            </button>
            <button class="btn btn-outline-secondary btn-cart">
              Add to Cart
            </button>
            {/* </CardContent>
          </Card> */}
          </div>
        </div>
      </div>
      <PaymentModal
        open={modalOpen}
        onClose={handleCloseModal}
        amount={amount}
        handlePayment={handlePayment}
      />
    </Container>
  );
};

export default RoomDetails;
